body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.exportUsersButton {
    width: 100px;
    height: 50px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #3f51b5;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;

    svg {
        margin-right: 5px;
    }
}

.subscription_details {
    display: flex;
    flex-direction: column;

    .main_inputs {
        display: flex;
        flex-direction: column;
        width: 500px;

        .input {
            margin-bottom: 40px;
        }

        .save-button {
            width: 100px;
        }
    }
}
