.pga_wrapper {
    margin: 0;
    padding: 0 10%;
    background-color: #000;
    max-width: 100vw;
    height: 100vh;
    color: #fff;
    display: block;
    // justify-content: center;
    // align-items: center;

    .main_container {
        height: 789px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-height: 900px) {
            height: 730px;
        }
    }
}

.spinner_page {
    width: 100vw;
    height: 100vh;
    background-color: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loader {
        border: 10px solid #f3f3f3;
        border-radius: 50%;
        border-top: 10px solid #614bef;
        width: 60px;
        height: 60px;
        -webkit-animation: spin 1s linear infinite; /* Safari */
        animation: spin 1s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    span {
        margin-top: 16px;
    }
}

.wrapper {
    background-color: #000;
    width: 100vw;
    height: 100vh;
}

.fetch_error {
    width: 100vw;
    height: 100vh;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
}
